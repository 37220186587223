import React from 'react';
// import { navigate } from '@reach/router';
// import { StaticQuery } from 'gatsby';
import styled from 'styled-components';

import Layout from '../components/Layout';

// import LocaleContext from '../context/Locale';

// Styled Components
const Par = styled.p`
  text-align: center;
  font-weight: bold;
`;
const ParHead = styled.p`
  text-align: center;
  font-weight: bold;
`;

// Main Component
function IndexPage() {
  return (
    <Layout shop={true}>
      <div
        className="px-6 pt-20 pb-20 container mx-auto w-full block pt-6 text-white"
        style={{ maxWidth: '700px' }}
      >
        <h1 className="text-center text-xl">Regulamin</h1> <Par>§ 1</Par>
        <ParHead>Postanowienia wstępne</ParHead> 
        <p>
          1. Sklep internetowy Potock.pl, dostępny pod adresem
          internetowym www.potock.pl prowadzony jest przez firmę „Kulka” –
          Łukasz Kulka, wpisaną do Centralnej Ewidencji i Informacji o
          Działalności Gospodarczej (CEIDG) prowadzonej przez ministra
          właściwego ds. gospodarki, NIP 5521565670, REGON 368484790.  
        </p>
         <Par>§ 2</Par>
        <ParHead>Definicje</ParHead>
        <p>
          1. Konsument - osoba fizyczna zawierająca ze Sprzedawcą umowę w ramach
          Sklepu, której przedmiot nie jest związany bezpośrednio z jej
          działalnością gospodarczą lub zawodową.
        </p>
        <p>
          2. Sprzedawca - osoba fizyczna prowadząca działalność gospodarczą pod
          firmą „Kulka” – Łukasz Kulka, wpisaną do Centralnej Ewidencji i
          Informacji o Działalności Gospodarczej (CEIDG) prowadzonej przez
          ministra właściwego ds. gospodarki, NIP 5521565670, REGON 368484790.
        </p>
        <p>
          3. Klient - każdy podmiot dokonujący zakupów za pośrednictwem Sklepu.
        </p>
        <p>
          4. Przedsiębiorca - osoba fizyczna, osoba prawna i jednostka
          organizacyjna niebędąca osobą prawną, której odrębna ustawa przyznaje
          zdolność prawną, wykonująca we własnym imieniu działalność
          gospodarczą, która korzysta ze Sklepu.
        </p>
        <p>
          5. Sklep - sklep internetowy prowadzony przez Sprzedawcę pod adresem
          internetowym www.potock.pl
        </p>
        <p>
          6. Umowa zawarta na odległość - umowa zawarta z Klientem w ramach
          zorganizowanego systemu zawierania umów na odległość (w ramach
          Sklepu), bez jednoczesnej fizycznej obecności stron, z wyłącznym
          wykorzystaniem jednego lub większej liczby środków porozumiewania się
          na odległość do chwili zawarcia umowy włącznie.
        </p>
        <p>7. Regulamin - niniejszy regulamin Sklepu.</p>
        <p>
          8. Zamówienie - oświadczenie woli Klienta składane za pomocą
          Formularza Zamówienia i zmierzające bezpośrednio do zawarcia Umowy
          Sprzedaży Produktu lub Produktów ze Sprzedawcą.
        </p>
        <p>
          9. Formularz zamówienia - interaktywny formularz dostępny w Sklepie
          umożliwiający złożenie Zamówienia, w szczególności poprzez dodanie
          Produktów do Koszyka oraz określenie warunków Umowy Sprzedaży, w tym
          sposobu dostawy i płatności.
        </p>
        <p>
          10. Koszyk – element oprogramowania Sklepu, w którym widoczne są
          wybrane przez Klienta Produkty do zakupu, a także istnieje możliwość
          ustalenia i modyfikacji danych Zamówienia, w szczególności ilości
          produktów.
        </p>
        <p>
          11. Produkt - dostępna w Sklepie rzecz ruchoma/usługa będąca
          przedmiotem Umowy Sprzedaży między Klientem a Sprzedawcą.
        </p>
        <p>
          12. Umowa Sprzedaży - umowa sprzedaży Produktu zawierana albo zawarta
          między Klientem a Sprzedawcą za pośrednictwem Sklepu internetowego.
          Przez Umowę Sprzedaży rozumie się też - stosowanie do cech Produktu -
          umowę o świadczenie usług i umowę o dzieło.
        </p>
         <Par>§ 3</Par>
        <ParHead>Kontakt ze Sklepem</ParHead>
        <p>1. Adres Sprzedawcy: Zawoja 2858, 34-222 Zawoja</p>
        <p>2. Adres e-mail Sprzedawcy: lukaszkulka@op.pl</p>
        <p>3. Numer telefonu Sprzedawcy: +48 504 138 656</p>
        <p>
          4. Numer rachunku bankowego Sprzedawcy: 90 2490 0005 0000 4600 2691
          9313
        </p>
        <p>
          5. Klient może porozumiewać się ze Sprzedawcą za pomocą adresów i
          numerów telefonów podanych w niniejszym paragrafie.
        </p>
        <p>
          6. Klient może porozumieć się telefonicznie ze Sprzedawcą w godzinach
          9.00-17.00
        </p>
        <Par>§ 4</Par>
        <ParHead>Wymagania techniczne</ParHead>
        <p>
          Do korzystania ze Sklepu, w tym przeglądania asortymentu Sklepu oraz
          składania zamówień na Produkty, niezbędne są:
        </p>
        <p>
          1. urządzenie końcowe z dostępem do sieci Internet i przeglądarką
          internetową
        </p>
        <p>2. aktywne konto poczty elektronicznej (e-mail),</p>
        <p>3. włączona obsługa plików cookies,,</p> <Par>§ 5</Par>
        <ParHead>Informacje ogólne</ParHead>
        <p>
          1. Sprzedawca w najszerszym dopuszczalnym przez prawo zakresie nie
          ponosi odpowiedzialności za zakłócenia w tym przerwy w funkcjonowaniu
          Sklepu spowodowane siłą wyższą, niedozwolonym działaniem osób trzecich
          lub niekompatybilnością Sklepu internetowego z infrastrukturą
          techniczną Klienta.
        </p>
        <p>
          2. Przeglądanie asortymentu Sklepu nie wymaga zakładania Konta.
          Składanie zamówień przez Klienta na Produkty znajdujące się w
          asortymencie Sklepu przez podanie niezbędnych danych osobowych i
          adresowych umożliwiających realizację Zamówienia bez zakładania Konta.
        </p>
        <p>
          3. Ceny podane w Sklepie są podane w polskich złotych i są cenami
          brutto (uwzględniają podatek VAT).
        </p>
        <p>
          4. Na końcową (ostateczną) kwotę do zapłaty przez Klienta składa się
          cena za Produkt oraz koszt dostawy (w tym opłaty za transport,
          dostarczenie i usługi pocztowe), o której Klient jest informowany na
          stronach Sklepu w trakcie składania Zamówienia, w tym także w chwili
          wyrażenia woli związania się Umową Sprzedaży.
        </p>
        <p>
          5. Gdy charakter przedmiotu Umowy nie pozwala, rozsądnie oceniając, na
          wcześniejsze obliczenie wysokości końcowej (ostatecznej) ceny,
          informacja o sposobie, w jaki cena będzie obliczana, a także o
          opłatach za transport, dostarczenie, usługi pocztowe oraz o innych
          kosztach, będzie podana w Sklepie w opisie Produktu.
        </p>
          
        <Par>§ 7</Par>
        <ParHead>Zasady składania Zamówienia</ParHead>
        <p>W celu złożenia Zamówienia należy:</p>
        <p>
          1. wybrać Produkt będący przedmiotem Zamówienia, a następnie kliknąć
          przycisk „Do koszyka” (lub równoznaczny);
        </p>
        <p>2. skorzystać z możliwości złożenia Zamówienia bez rejestracji;</p>
        <p>
          3. wypełnić Formularz zamówienia poprzez wpisanie danych odbiorcy
          Zamówienia oraz adresu, na który ma nastąpić dostawa Produktu, wybrać
          rodzaj przesyłki (sposób dostarczenia Produktu), wpisać dane do
          faktury, jeśli są inne niż dane odbiorcy Zamówienia,
        </p>
        <p>4. kliknąć przycisk “Zamawiam i płacę”,</p>
        <p>
          5. wybrać jeden z dostępnych sposobów płatności i w zależności od
          sposobu płatności, opłacić zamówienie w określonym terminie, z
          zastrzeżeniem § 8 pkt 3.
        </p>
         <Par>§ 8</Par>
        <ParHead>Oferowane metody dostawy oraz płatności</ParHead>
        <p>
          1. Klient może skorzystać z następujących metod dostawy zamówionego
          Produktu:
        </p>
        <ul>
          {/* <li>Przesyłka do paczkomatu opłacona z góry</li> */}
          <li>Przesyłka kurierska opłacona z góry</li>
          <li>Odbiór osobisty opłacony z góry</li>
        </ul>
        <p>2. Klient może skorzystać z następujących metod płatności:</p>
        <ul>
          <li>Płatność przelewem na konto Sprzedawcy</li>
          <li>Płatności elektroniczne</li>
          <li>Płatność kartą płatniczą.</li>
        </ul>
        <p>
          3. Szczegółowe informacje na temat metod dostawy oraz akceptowalnych
          metod płatności znajdują się za stronach Sklepu.
        </p>
        <Par>§ 9</Par>
        <ParHead>Wykonanie umowy sprzedaży</ParHead>
        <p>
            1. Zawarcie Umowy Sprzedaży między Klientem a Sprzedawcą następuje
          po uprzednim złożeniu przez Klienta Zamówienia za pomocą Formularza
          zamówienia w Sklepie internetowym zgodnie z § 7 Regulaminu.
        </p>
        <p>
          2. Po złożeniu Zamówienia Sprzedawca niezwłocznie potwierdza jego
          otrzymanie oraz jednocześnie przyjmuje Zamówienie do realizacji.
          Potwierdzenie otrzymania Zamówienia i jego przyjęcie do realizacji
          następuje poprzez przesłanie przez Sprzedawcę Klientowi stosownej
          wiadomości e-mail na podany w trakcie składania Zamówienia adres
          poczty elektronicznej Klienta, która zawiera co najmniej oświadczenia
          Sprzedawcy o otrzymaniu Zamówienia i o jego przyjęciu do realizacji
          oraz potwierdzenie zawarcia Umowy Sprzedaży. Z chwilą otrzymania przez
          Klienta powyższej wiadomości e-mail zostaje zawarta Umowa Sprzedaży
          między Klientem a Sprzedawcą.
        </p>
        <p>
          3. W przypadku wyboru przez Klienta: płatności przelewem, płatności
          elektronicznych albo płatności kartą płatniczą, Klient obowiązany jest
          do dokonania płatności w terminie 3 dni kalendarzowych od dnia
          zawarcia Umowy Sprzedaży - w przeciwnym razie zamówienie zostanie
          anulowane.
        </p>
        <p>
          4. Produkt zostanie wysłany przez Sprzedawcę w terminie wskazanym w
          jego opisie (z zastrzeżeniem ustępu 5 niniejszego paragrafu), w sposób
          wybrany przez Klienta podczas składania Zamówienia.
        </p>
        <p>
          A. W przypadku zamówienia Produktów o różnych terminach dostawy,
          terminem dostawy jest najdłuższy podany termin.
        </p>
        <p>
          B. W przypadku zamówienia Produktów o różnych terminach dostawy,
          Klient ma możliwość żądania dostarczenia Produktów częściami lub też
          dostarczenia wszystkich Produktów po skompletowaniu całego zamówienia.
        </p>
        <p>
          5. Początek biegu terminu dostawy Produktu do Klienta liczy się od
          dnia uznania rachunku bankowego Sprzedawcy.
        </p>
        <p>6. Dostawa Produktu odbywa się na terenie naszego kraju - Polski.</p>
        <p>
          7. Dostawa Produktu do Klienta jest odpłatna, chyba że Umowa Sprzedaży
          stanowi inaczej. Koszty dostawy Produktu (w tym opłaty za transport,
          dostarczenie i usługi pocztowe) są wskazywane Klientowi na stronach
          Sklepu internetowego w zakładce „Koszty dostawy” oraz w trakcie
          składania Zamówienia, w tym także w chwili wyrażenia przez Klienta
          woli związania się Umową Sprzedaży.
        </p>
         <Par>§ 10</Par>
        <ParHead>Prawo odstąpienia od umowy</ParHead>
        <p>
          1. Konsument może w terminie 14 dni odstąpić od Umowy Sprzedaży bez
          podania jakiejkolwiek przyczyny.
        </p>
        <p>
          2. Bieg terminu określonego w ust. 1 rozpoczyna się od dostarczenia
          Produktu Konsumentowi lub wskazanej przez niego osobie innej niż
          przewoźnik.
        </p>
        <p>
          3. W przypadku Umowy, która obejmuje wiele Produktów, które są
          dostarczane osobno, partiami lub w częściach, termin wskazany w ust. 1
          biegnie od dostawy ostatniej rzeczy, partii lub części.
        </p>
        <p>
          4. Konsument może odstąpić od Umowy, składając Sprzedawcy oświadczenie
          o odstąpieniu od Umowy. Do zachowania terminu odstąpienia od Umowy
          wystarczy wysłanie przez Konsumenta oświadczenia przed upływem tego
          terminu.
        </p>
        <p>
          5. Oświadczenie może być wysłane za pomocą tradycyjnej poczty bądź
          drogą elektroniczną poprzez przesłanie oświadczenia na adres e-mail
          Sprzedawcy lub przez złożenie oświadczenia na stronie internetowej
          Sprzedawcy - dane kontaktowe Sprzedawcy zostały określone w § 3.
          Oświadczenie można złożyć również na formularzu, którego wzór stanowi
          załącznik nr 1 do niniejszego Regulaminu oraz załącznik do ustawy z
          dnia 30 maja 2014 roku o prawach konsumenta, jednak nie jest to
          obowiązkowe.
        </p>
        <p>
          6. W przypadku przesłania oświadczenia przez Konsumenta drogą
          elektroniczną, Sprzedawca niezwłocznie prześle Konsumentowi na podany
          przez Konsumenta adres e-mail potwierdzenie otrzymania oświadczenia o
          odstąpieniu od Umowy.
        </p>
        <p>7. Skutki odstąpienia od Umowy:</p>
        <p>
          A. W przypadku odstąpienia od Umowy zawartej na odległość Umowę uważa
          się za niezawartą.
        </p>
        <p>
          B. W przypadku odstąpienia od Umowy Sprzedawca zwraca Konsumentowi
          niezwłocznie, nie później niż w terminie 14 dni od dnia otrzymania
          oświadczenia Konsumenta o odstąpieniu od Umowy, wszystkie dokonane
          przez niego płatności, w tym koszty dostarczenia rzeczy, z wyjątkiem
          dodatkowych kosztów wynikających z wybranego przez Konsumenta sposobu
          dostarczenia innego niż najtańszy zwykły sposób dostarczenia oferowany
          przez Sprzedawcę.
        </p>
        <p>
          C. Zwrotu płatności Sprzedawca dokona przy użyciu takich samych metod
          płatności, jakie zostały przez Konsumenta użyte w pierwotnej
          transakcji, chyba że Konsument wyraźnie zgodził się na inne
          rozwiązanie, które nie będzie się wiązało dla niego z żadnymi
          kosztami.
        </p>
        <p>
          D. Sprzedawca może wstrzymać się ze zwrotem płatności do czasu
          otrzymania Produktu z powrotem lub do czasu dostarczenia mu dowodu
          jego odesłania, w zależności od tego, które zdarzenie nastąpi
          wcześniej.
        </p>
        <p>
          E. Konsument powinien odesłać Produkt na adres Sprzedawcy podany w
          niniejszym Regulaminie niezwłocznie, nie później niż 14 dni od dnia, w
          którym poinformował Sprzedawcę o odstąpieniu od Umowy. Termin zostanie
          zachowany, jeśli Konsument odeśle Produkt przed upływem terminu 14
          dni.
        </p>
        <p>
          F. Konsument ponosi bezpośrednie koszty zwrotu Produktu, także koszty
          zwrotu Produktu, jeśli ze względu na swój charakter Produkt ten nie
          mógł zostać w zwykłym trybie odesłany pocztą.
        </p>
        <p>
          G. Konsument odpowiada tylko za zmniejszenie wartości Produktu
          wynikające z korzystania z niego w sposób inny niż było to konieczne
          do stwierdzenia charakteru, cech i funkcjonowania Produktu.
        </p>
         <Par>§ 11</Par>
        <ParHead>Reklamacja i gwarancja</ParHead>
        <p>1. Umową Sprzedaży objęte są nowe Produkty.</p>
        <p>
          2. W przypadku wystąpienia wady zakupionego u Sprzedawcy towaru Klient
          ma prawo do reklamacji w oparciu o przepisy dotyczące rękojmi w
          kodeksie cywilnym.
        </p>
        <p>
          3. Reklamację należy zgłosić pisemnie lub drogą elektroniczną na
          podane w niniejszym Regulaminie adresy Sprzedawcy.
        </p>
        <p>
          4. Zaleca się, aby w reklamacji zawrzeć m.in. zwięzły opis wady,
          okoliczności (w tym datę) jej wystąpienia, dane Klienta składającego
          reklamację, oraz żądanie Klienta w związku z wadą towaru.
        </p>
        <p>
          5. Sprzedawca ustosunkuje się do żądania reklamacyjnego niezwłocznie,
          nie później niż w terminie 14 dni, a jeśli nie zrobi tego w tym
          terminie, uważa się, że żądanie Klienta uznał za uzasadnione.
        </p>
        <p>
          6. Towary odsyłane w ramach procedury reklamacyjnej należy wysyłać na
          adres podany w § 3 niniejszego Regulaminu.
        </p>
           <Par>§ 12</Par>
        <ParHead>
          Pozasądowe sposoby rozpatrywania reklamacji i dochodzenia roszczeń
        </ParHead>
        <p>
          1. Szczegółowe informacje dotyczące możliwości skorzystania przez
          Konsumenta z pozasądowych sposobów rozpatrywania reklamacji i
          dochodzenia roszczeń oraz zasady dostępu do tych procedur dostępne są
          w siedzibach oraz na stronach internetowych powiatowych (miejskich)
          rzeczników konsumentów, organizacji społecznych, do których zadań
          statutowych należy ochrona konsumentów, Wojewódzkich Inspektoratów
          Inspekcji Handlowej oraz pod następującymi adresami internetowymi
          Urzędu Ochrony Konkurencji i Konsumentów:
          http://www.uokik.gov.pl/spory_konsumenckie.php;
          http://www.uokik.gov.pl/sprawy_indywidualne.php oraz
          http://www.uokik.gov.pl/wazne_adresy.php.
        </p>
        <p>
          2. Konsument posiada następujące przykładowe możliwości skorzystania z
          pozasądowych sposobów rozpatrywania reklamacji i dochodzenia roszczeń:
        </p>
        <p>
          A. Konsument uprawniony jest do zwrócenia się do stałego polubownego
          sądu konsumenckiego, o którym mowa w art. 37 ustawy z dnia 15 grudnia
          2000 r. o Inspekcji Handlowej (Dz.U. z 2014 r. poz. 148 z późn. zm.),
          z wnioskiem o rozstrzygnięcie sporu wynikłego z Umowy zawartej ze
          Sprzedawcą.
        </p>
        <p>
          B. Konsument uprawniony jest do zwrócenia się do wojewódzkiego
          inspektora Inspekcji Handlowej, zgodnie z art. 36 ustawy z dnia 15
          grudnia 2000 r. o Inspekcji Handlowej (Dz.U. z 2014 r. poz. 148 z
          późn. zm.), z wnioskiem o wszczęcie postępowania mediacyjnego w
          sprawie polubownego zakończenia sporu między Konsumentem a Sprzedawcą.
        </p>
        <p>
          C. Konsument może uzyskać bezpłatną pomoc w sprawie rozstrzygnięcia
          sporu między nim a Sprzedawcą, korzystając także z bezpłatnej pomocy
          powiatowego (miejskiego) rzecznika konsumentów lub organizacji
          społecznej, do której zadań statutowych należy ochrona konsumentów
          (m.in. Federacja Konsumentów, Stowarzyszenie Konsumentów Polskich).
        </p>
         <Par>§ 13</Par>
        <ParHead>Dane osobowe w Sklepie internetowym</ParHead>
        <p>
          1. Administratorem danych osobowych Klientów zbieranych za
          pośrednictwem Sklepu internetowego jest Sprzedawca.
        </p>
        <p>
          2. Dane osobowe Klientów zbierane przez administratora za
          pośrednictwem Sklepu internetowego zbierane są w celu realizacji Umowy
          Sprzedaży, a jeżeli Klient wyrazi na to zgodę - także w celu
          marketingowym.
        </p>
        <p>
          3. Odbiorcami danych osobowych Klientów Sklepu internetowego mogą być:
        </p>
        <p>
          A. W przypadku Klienta, który korzysta w Sklepie internetowym ze
          sposobu dostawy przesyłką pocztową lub przesyłką kurierską,
          Administrator udostępnia zebrane dane osobowe Klienta wybranemu
          przewoźnikowi lub pośrednikowi realizującemu przesyłki na zlecenie
          Administratora.
        </p>
        <p>
          B. W przypadku Klienta, który korzysta w Sklepie internetowym ze
          sposobu płatności elektronicznych lub kartą płatniczą Administrator
          udostępnia zebrane dane osobowe Klienta, wybranemu podmiotowi
          obsługującemu powyższe płatności w Sklepie internetowym.
        </p>
        <p>
          4. Klient ma prawo dostępu do treści swoich danych oraz ich
          poprawiania.
        </p>
        <p>
          5. Podanie danych osobowych jest dobrowolne, aczkolwiek niepodanie
          wskazanych w Regulaminie danych osobowych niezbędnych do zawarcia
          Umowy Sprzedaży skutkuje brakiem możliwości zawarcia tejże umowy.
        </p>
         <Par>§ 14</Par>
        <ParHead>Postanowienia końcowe</ParHead>
        <p>
          1. Umowy zawierane poprzez Sklep internetowy zawierane są w języku
          polskim.
        </p>
        <p>
          2. Sprzedawca zastrzega sobie prawo do dokonywania zmian Regulaminu z
          ważnych przyczyn to jest: zmiany przepisów prawa, zmiany sposobów
          płatności i dostaw - w zakresie, w jakim te zmiany wpływają na
          realizację postanowień niniejszego Regulaminu. O każdej zmianie
          Sprzedawca poinformuje Klienta z co najmniej 7 dniowym wyprzedzeniem.
        </p>
        <p>
          3. W sprawach nieuregulowanych w niniejszym Regulaminie mają
          zastosowanie powszechnie obowiązujące przepisy prawa polskiego, w
          szczególności: Kodeksu cywilnego; ustawy o świadczeniu usług drogą
          elektroniczną; ustawy o prawach konsumenta, ustawy o ochronie danych
          osobowych.
        </p>
        <p>
          4. Klient ma prawo skorzystać z pozasądowych sposobów rozpatrywania
          reklamacji i dochodzenia roszczeń. W tym celu może złożyć skargę za
          pośrednictwem unijnej platformy internetowej ODR dostępnej pod
          adresem: http://ec.europa.eu/consumers/odr/.
        </p>
      </div>
    </Layout>
  );
}

export default IndexPage;
